import React from 'react';
import { connect } from 'react-redux'
import Config from "../../../Config";
import { _getToken, _error, _success, _validationFormMsg, _setAxios, _tableLogActivity, _getBase64, _getBase64Upload, _getBase64ImageUrl } from '../../../lib/Helper';
import { bidangLombaOptions, tingkatLombaOptions, peringkatLombaOptions } from '../../../data/options';
import { schemaPrestasi } from '../schema';
import { Table, Row, Col, Divider, Popover, Modal, Button, Radio, Space, message, Upload, PageHeader, Dropdown, Menu } from 'antd';
import { Input, Tooltip } from 'antd';
import { DownloadOutlined, InfoCircleOutlined, SearchOutlined, ArrowUpOutlined, ArrowDownOutlined, SortAscendingOutlined, ExclamationCircleOutlined, InboxOutlined, PlusOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import validate from 'validate.js';
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

const { Dragger } = Upload;

class Pendidikan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tableOption: {
                sortField: "id",
                sortValue: "DESC",
            },
            data: [],
            search: "",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            visibleModalCreate: false,
            id: 0,
            values: {},
            errors: {},
            create: {
                visible: false,
                loading: false,
            },
            update: {
                visible: false,
                loading: false,
                fileList: [],
                validateFile: false
            },
            delete: {
                loading: false,
            },
            dataID: this.props.dataID
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        var params = {
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
            search: this.state.search,
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSearch = (e) => {
        const { value } = e.target;

        this.setState({
            search: value,
        });

        var params = {
            search: value,
            pagination: { ...this.state.pagination, current: 1 },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);

    };

    handleTableFilter = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortField: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: e.target.value,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSort = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortValue: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: e.target.value,
                urutan: 1,
            }]
        });
    };

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        const headers = {
            'Accept': 'application/json',
            'Authorization': _getToken()
        };

        axios.post(Config.api + '/siswa/prestasi/table/' + this.state.dataID, JSON.stringify(params), { headers })
            .then((resp) => {
                this.setState({
                    loading: false,
                    data: resp.data.list,
                    pagination: {
                        ...params.pagination,
                        total: resp.data.info.total,
                    },
                });
            })
            .catch(function (error) {
                if (error.response.data.message) {
                    _error('topRight', 'Failed', error.response.data.message)
                }
            });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    handleChangeSetValue = (name, value) => {
        this.setForm(name, value)
    };

    setForm = (name, value, row = {}) => {
        const { values } = this.state;
        values[name] = value;
        const errors = validate(values, schemaPrestasi);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    resetForm = () => {
        this.setState({
            values: {},
            errors: {},
        });
    };

    beforeUploadFilePrestasi = (file) => {
        const isJpgOrPngPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPngPdf) {
            message.error('Anda bisa upload JPG/PNG/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }

        const flag = isJpgOrPngPdf && isLt2M

        if (flag === false) {
            this.setState({
                update: {
                    ...this.state.update,
                    validateFile: false,
                }
            })
            return false
        } else {
            this.setState({
                update: {
                    ...this.state.update,
                    validateFile: true,
                }
            })
        }
        return true
    };

    handleChangeFilePrestasi = ({ fileList }) => {
        if (this.state.update.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                update: {
                    ...this.state.update,
                    fileList: fileList
                }
            })
        } else {
            this.setState({
                update: {
                    ...this.state.update,
                    fileList: null
                }
            })
        }
    };

    // CREATE START ------------------------------------------
    showModalCreate = () => {
        this.setState({
            create: {
                ...this.state.create,
                visible: true,
            },

        });
    };

    hideModalCreate = () => {
        this.setState({
            create: {
                visible: false,
                loading: false,
            },

        });
    };

    createSubmitPrestasi = async (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schemaPrestasi);
        if (errors) {
            return _validationFormMsg(errors)
        }

        let file = null
        const fileList = this.state.update.fileList
        if (this.state.update.fileList.length > 0) {
            file = await _getBase64(fileList[0].originFileObj)
            file = _getBase64Upload(file)
        } else {
            message.warning("File tidak boleh kosong");
            return
        }

        const params = [{
            "tahun": values.prestasi_tahun && values.prestasi_tahun instanceof Date ? values.prestasi_tahun.getFullYear() : null,
            "nama": values.prestasi_nama ? values.prestasi_nama : null,
            "bidang_lomba": values.prestasi_bidang_lomba ? values.prestasi_bidang_lomba.value : null,
            "nama_penyelenggara": values.prestasi_nama_penyelenggara ? values.prestasi_nama_penyelenggara : null,
            "lomba_tingkat": values.prestasi_lomba_tingkat ? values.prestasi_lomba_tingkat.value : null,
            "peringkat": values.prestasi_peringkat ? values.prestasi_peringkat.value : null,
            "fileList": fileList,
            "file": file ? file : null,
        }]

        this.setState({
            create: {
                ...this.state.create,
                loading: true
            }
        });

        _setAxios("siswa/prestasi/" + this.state.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.hideModalCreate()
                this.resetForm()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    create: {
                        ...this.state.create,
                        loading: false
                    }
                });
            }
        })
    };

    // CREATE END ------------------------------------------

    // UPDATE START ------------------------------------------
    showModalUpdate = (row) => {
        this.setState({
            update: {
                ...this.state.update,
                visible: true,
                loading: false,
                fileList: []
            },

            id: row.id,
            values: {
                prestasi_nama: row.nama,
                prestasi_bidang_lomba: {
                    value: row.bidang_lomba,
                    label: row.bidang_lomba,
                },
                prestasi_nama_penyelenggara: row.nama_penyelenggara,
                prestasi_lomba_tingkat: {
                    value: row.lomba_tingkat,
                    label: row.lomba_tingkat,
                },
                prestasi_peringkat: {
                    value: row.peringkat,
                    label: row.peringkat,
                },
                prestasi_tahun: new Date(row.tahun, 11, 24, 10, 33, 30, 0),
            },
        });
    };

    hideModalUpdate = () => {
        this.setState({
            update: {
                visible: false,
                loading: false
            }
        });
    };

    updateSubmitPrestasi = async (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schemaPrestasi);
        if (errors) {
            return _validationFormMsg(errors)
        }

        var file = null
        const fileList = this.state.update.fileList
        if (this.state.update.fileList.length > 0) {
            if (fileList[0].url.includes("https://") || fileList[0].url.includes("http://")) {
                await _getBase64ImageUrl(fileList[0].url).then(dataUrl => {
                    file = _getBase64Upload(dataUrl)
                })
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }
        }

        const params = {
            "tahun": values.prestasi_tahun && values.prestasi_tahun instanceof Date ? values.prestasi_tahun.getFullYear() : null,
            "nama": values.prestasi_nama ? values.prestasi_nama : null,
            "bidang_lomba": values.prestasi_bidang_lomba ? values.prestasi_bidang_lomba.value : null,
            "nama_penyelenggara": values.prestasi_nama_penyelenggara ? values.prestasi_nama_penyelenggara : null,
            "lomba_tingkat": values.prestasi_lomba_tingkat ? values.prestasi_lomba_tingkat.value : null,
            "peringkat": values.prestasi_peringkat ? values.prestasi_peringkat.value : null,
            "file": file ? file : null,
        }

        this.setState({
            update: {
                ...this.state.update,
                loading: true
            }
        });
        _setAxios("siswa/prestasi/" + this.state.id, "PUT", params).then(resp => {
            if (resp.status === true) {
                this.hideModalUpdate()
                this.resetForm()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    update: {
                        ...this.state.update,
                        loading: false
                    }
                });
            }
        })

    };
    // UPDATE END ------------------------------------------

    // DELETE START ------------------------------------------
    onDeleteSubmitPrestasi = (row) => {
        Modal.confirm({
            title: 'Apakah Anda yakin?',
            icon: <ExclamationCircleOutlined />,
            content: 'Pendidikan [' + row.nama_lembaga + '] akan dihapus dari list.',
            onOk: () => {
                this.setState({
                    delete: {
                        loading: true
                    }
                });

                _setAxios("siswa/prestasi/" + row.id, "DELETE").then(resp => {
                    if (resp.status === true) {
                        this.setState({
                            delete: {
                                loading: false
                            }
                        });
                        _success('topRight', 'Success', resp.data.message)
                        this.fetchTable()
                    } else {
                        this.setState({
                            delete: {
                                loading: false
                            }
                        });
                    }
                })
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    };

    // DELETE END ------------------------------------------


    render() {
        const action = this.props.action
        const { data, pagination, loading, values, errors, tableOption } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
                <Space direction="vertical">
                    <Radio value="nama">Nama</Radio>
                    <Radio value="id">Tanggal Pembuatan</Radio>
                </Space>
            </Radio.Group>

            <Divider orientation="left"></Divider>

            <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
                <Space direction="vertical">
                    <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
                    <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
                </Space>
            </Radio.Group>
        </>

        const formInput = <>
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            views={['year']}
                            disableHighlightToday={true}
                            label="Tahun"
                            value={values.prestasi_tahun || ""}
                            onChange={(newValue) => {
                                this.handleChangeSetValue("prestasi_tahun", newValue)
                            }}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                size="small"
                                error={this.hasError('prestasi_tahun')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    this.hasError('prestasi_tahun') ? errors.prestasi_tahun[0] : null
                                }
                            />}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Lomba"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="prestasi_nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.prestasi_nama || ""}
                        error={this.hasError('prestasi_nama')}
                        helperText={
                            this.hasError('prestasi_nama') ? errors.prestasi_nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Penyelenggara"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="prestasi_nama_penyelenggara"
                        type="text"
                        onChange={this.handleChange}
                        value={values.prestasi_nama_penyelenggara || ""}
                        error={this.hasError('prestasi_nama_penyelenggara')}
                        helperText={
                            this.hasError('prestasi_nama_penyelenggara') ? errors.prestasi_nama_penyelenggara[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={bidangLombaOptions}
                        value={values.prestasi_bidang_lomba || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("prestasi_bidang_lomba", newValue)
                        }}
                        name="prestasi_bidang_lomba"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Bidang Lomba"
                            size="small"
                            error={this.hasError('prestasi_bidang_lomba')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('prestasi_bidang_lomba') ? errors.prestasi_bidang_lomba[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={tingkatLombaOptions}
                        value={values.prestasi_lomba_tingkat || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("prestasi_lomba_tingkat", newValue)
                        }}
                        name="prestasi_lomba_tingkat"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Tingkat Lomba"
                            size="small"
                            error={this.hasError('prestasi_lomba_tingkat')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('prestasi_lomba_tingkat') ? errors.prestasi_lomba_tingkat[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={peringkatLombaOptions}
                        value={values.prestasi_peringkat || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("prestasi_peringkat", newValue)
                        }}
                        name="prestasi_peringkat"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Peringkat yang diraih"
                            size="small"
                            error={this.hasError('prestasi_peringkat')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('prestasi_peringkat') ? errors.prestasi_peringkat[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24}>
                    {/* <Dragger {...propsDragger}> */}
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        maxCount={1}
                        fileList={this.state.update.fileList}
                        onChange={this.handleChangeFilePrestasi}
                        beforeUpload={this.beforeUploadFilePrestasi}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">
                            File yang diperbolehkan untuk diunggah: JPG, PNG & PDF
                        </p>
                    </Dragger>
                </Col>
            </Row>
        </>

        const modalCreate = <Modal
            title="Tambah Prestasi"
            visible={this.state.create.visible}
            onCancel={this.hideModalCreate}
            footer={<>
                <Button onClick={this.hideModalCreate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.createSubmitPrestasi} loading={this.state.create.loading}>Simpan</Button>
            </>}
        >
            <form autoComplete="off" onSubmit={this.createSubmitPrestasi}>
                {formInput}
            </form>
        </Modal>

        const modalUpdate = <Modal
            title="Update Prestasi"
            onCancel={this.hideModalUpdate}
            visible={this.state.update.visible}
            footer={<>
                <Button onClick={this.hideModalUpdate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.updateSubmitPrestasi} loading={this.state.update.loading}>Simpan</Button>
            </>}

        >
            <form autoComplete="off" onSubmit={this.updateSubmitPrestasi}>
                {formInput}
            </form>
        </Modal>

        const modalDelete = <Modal
            title={<><ExclamationCircleOutlined /> Confirm</>}
            visible={this.state.delete.visible}
            onCancel={this.hideModalDelete}
            footer={<>
                <Button onClick={this.hideModalDelete}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Simpan</Button>
            </>}
        >
            Apakah Anda Yakin?

        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Prestasi"
                    extra={action.prestasi_view && <Button type='primary' className='nav-btn-page' icon={<PlusOutlined />} onClick={this.showModalCreate}> Tambah</Button>}
                />
                <Row gutter={[16, 16]}>
                    <Col xs={16} sm={18} md={20} lg={22}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={this.handleTableSearch}
                            value={this.state.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={8} sm={6} md={4} lg={2} className="text-align-right">
                        <Popover placement="bottom" content={sortComponent} trigger="click">
                            <Button block><SortAscendingOutlined /> Sort</Button>
                        </Popover>
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    sorter: false,
                                    width: '3%',
                                },
                                {
                                    title: 'Tahun',
                                    dataIndex: 'tahun',
                                    sorter: false,
                                },
                                {
                                    title: 'Nama',
                                    dataIndex: 'nama',
                                    sorter: false,
                                },
                                {
                                    title: 'Bidang Lomba',
                                    dataIndex: 'bidang_lomba',
                                    sorter: false,
                                },
                                {
                                    title: 'Nama Penyelenggara',
                                    dataIndex: 'nama_penyelenggara',
                                    sorter: false,
                                },
                                {
                                    title: 'Tingkat Lomba',
                                    dataIndex: 'lomba_tingkat',
                                    sorter: false,
                                },
                                {
                                    title: 'Peringkat',
                                    dataIndex: 'peringkat',
                                    sorter: false,
                                },
                                {
                                    title: 'File',
                                    dataIndex: 'file',
                                    sorter: false,
                                    render: (a, row) => <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a>,
                                }, 
                                {
                                    title: <EllipsisOutlined />,
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown overlay={<Menu>
                                        {action.prestasi_update === true ? (<Menu.Item key={0} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                                        {action.prestasi_delete === true ? (<Menu.Item key={1} onClick={this.onDeleteSubmitPrestasi.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <EllipsisOutlined />
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={this.handleTableChange}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalCreate}

                {modalUpdate}

                {modalDelete}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Pendidikan);
