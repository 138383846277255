import React from 'react';

import { Drawer, List } from 'antd';
import { MobileView } from 'react-device-detect';
import { TabBar } from 'antd-mobile'

import './style.css'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const pathKesiswaan = ["/kesiswaan/tata-tertib-sekolah", "/kesiswaan/pelanggaran-siswa", "/kesiswaan/penghargaan-siswa"]
const pathBk = ["/bimbingan-konseling/profil-bk", "/bimbingan-konseling/hasil-tes", "/bimbingan-konseling/media-informasi", "/bimbingan-konseling/konsultasi"]

class TabBarFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname: null,
      tabbar: {
        visible: false,
        title: null,
        data: []
      }
    }
  }

  componentDidMount() {
    const { pathname } = window.location
    if(pathname){
      let pathAr = pathname.split('/');
      if (pathAr.length > 1) {
        this.setState({
          pathname: pathAr[1]
        })
        console.log("pathAr[1]", pathAr[1])
      }

    }

    this.setState({
      pathname: pathname
    })

  }

  setRouteActive = (path) => {
    if (path === "kesiswaan" || path === "ekstrakurikuler-pramuka" || path === "bimbingan-konseling" || path === "presensi") {

      let title = ""
      let data = []
      
      if (path === "presensi") {
        title = "Presensi"
        data = [
          {
            title: "Siswa",
            path: "/presensi/siswa",
          },
          {
            title: "Pramuka",
            path: "/presensi/pramuka",
          },
          {
            title: "Perizinan",
            path: "/perizinan/siswa",
          }
        ]
      } else if (path === "kesiswaan" || path === "ekstrakurikuler-pramuka" || path === "kalender-kegiatan") {
        title = "Kesiswaan & Kegiatan"
        data = [
          {
            title: "Kalender",
            path: "/kalender-kegiatan/kalender",
          },
          {
            title: "Tata Tertib Sekolah",
            path: "/kesiswaan/tata-tertib-sekolah",
          },
          {
            title: "Pelanggaran Siswa",
            path: "/kesiswaan/pelanggaran-siswa",
          },
          {
            title: "Penghargaan Siswa",
            path: "/kesiswaan/penghargaan-siswa",
          },
          {
            title: "Ekstrakurikuler",
            path: "/ekstrakurikuler-pramuka/ekstrakurikuler",
          },
          {
            title: "Pramuka",
            path: "/ekstrakurikuler-pramuka/pramuka",
          }
        ]
      } else if (path === "bimbingan-konseling") {
        title = "Bimbingan Konseling"
        data = [
          {
            title: "Profil Bimbingan Konseling",
            path: "/bimbingan-konseling/profil-bk",
          },
          {
            title: "Hasil Tes Peserta Didik",
            path: "/bimbingan-konseling/hasil-tes",
          },
          {
            title: "Media Informasi",
            path: "/bimbingan-konseling/media-informasi",
          },
          {
            title: "Konsultasi",
            path: "/bimbingan-konseling/konsultasi",
          },
        ]
      }
      this.setState({
        tabbar: {
          ...this.state.tabbar,
          visible: true,
          title: title,
          data: data
        },
      })
    } else {
      this.props.history.push(path);
      this.setState({
        pathname: path
      })
    }
  }

  render() {
    let tabs = []
    if (this.props.privilege.profile_ex && this.props.privilege.profile_ex.siswa_alasan_akun_ditangguhkan) {
      tabs = [
        {
          key: '/',
          title: 'Dashboard',
          icon: <span className="icon-tab-bar icon-dashboard"></span>,
        },
      ]
    } else {
      if (this.props.privilege.profile && (this.props.privilege.profile.roles.toLowerCase() === "siswa" || this.props.privilege.profile.roles.toLowerCase() === "wali siswa")) {
        tabs = [
          {
            key: '/',
            title: 'Dashboard',
            icon: <span className="icon-tab-bar icon-dashboard"></span>,
          },
          {
            key: 'presensi',
            title: <div style={{ color: pathKesiswaan.includes(this.state.pathname) ? '#1677ff' : null }} >Presensi</div>,
            icon: <span style={{ color: pathKesiswaan.includes(this.state.pathname) ? '#1677ff' : null }} className="icon-tab-bar icon-circle"></span>,
          },
          {
            key: 'kesiswaan',
            title: <div style={{ color: pathKesiswaan.includes(this.state.pathname) ? '#1677ff' : null }} >Kesiswaan</div>,
            icon: <span style={{ color: pathKesiswaan.includes(this.state.pathname) ? '#1677ff' : null }} className="icon-tab-bar icon-kesiswaan"></span>,
          },
          {
            key: 'bimbingan-konseling',
            title: <div style={{ color: pathBk.includes(this.state.pathname) ? '#1677ff' : null }} >Bimbingan Konseling</div>,
            icon: <span style={{ color: pathBk.includes(this.state.pathname) ? '#1677ff' : null }} className="icon-tab-bar icon-bk"></span>,
          },
          {
            key: '/peserta-didik/aktif',
            title: 'Profil',
            icon: <span className="icon-tab-bar icon-profile"></span>,
          },
        ];
      } else if (this.props.privilege.profile && this.props.privilege.profile.roles.toLowerCase() === "alumni") {
        tabs = [
          {
            key: '/',
            title: 'Dashboard',
            icon: <span className="icon-tab-bar icon-dashboard"></span>,
          },
          {
            key: '/peserta-didik/alumni',
            title: 'Profil',
            icon: <span className="icon-tab-bar icon-profile"></span>,
          },
        ]
      }
    }

    return (
      <>
        <MobileView>
          <TabBar
            className='tab-bar'
            activeKey={123}
            onChange={value => this.setRouteActive(value)}
          >
            {tabs.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} />))}
          </TabBar>
        </MobileView>

        <Drawer
          title={this.state.tabbar.title}
          placement="bottom"
          className='tab-bar-drawer'
          height={260}
          onClose={() => {
            this.setState({
              tabbar: {
                ...this.state.tabbar,
                visible: false
              }
            })
          }}
          onClick={() => {
            if(window.location.pathname){
              let pathAr = window.location.pathname.split('/');
              if (pathAr.length > 1) {
                this.setState({
                  pathname: pathAr[1]
                })
              }
            }
          }}
          visible={this.state.tabbar.visible}
        >
          <List
            bordered
            dataSource={this.state.tabbar.data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  onClick={() => {
                    this.setState({
                      tabbar: {
                        ...this.state.tabbar,
                        visible: false
                      },
                      pathname: item.path
                    })
                    this.props.history.push(item.path);
                  }}
                  title={item.title}
                />
              </List.Item>
            )}
          />
        </Drawer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
    menu: state.menu,
    menuCollapse: state.menuCollapse
  }
}
export default connect(mapStateToProps)(withRouter(TabBarFooter));
